import React from "react";
import theme from "theme";
import { Theme, Section, Link, Box, Text, List, Icon, Hr, Image, Strong, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack, Formspree } from "@quarkly/components";
import * as Components from "components";
import { BsCircleFill } from "react-icons/bs";
import { FaMapPin } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Venta - Trans Europe
			</title>
			<meta name={"description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:title"} content={"Venta-Trans Europe - Freight Forwarding Company"} />
			<meta property={"og:description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/og_img.jpg?v=2021-04-21T14:38:42.609Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/favicon-32x32.png?v=2021-04-22T09:26:54.780Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
		</Helmet>
		<Section
			height="1px"
			left={0}
			lg-height="60px"
			lg-padding="10px 0 10px 0"
			max-width="1px"
			max-height="1px"
			top={0}
			width="1px"
			z-index="-1"
			min-width="0px"
			min-height="0px"
			id="top"
			background="#ffffff"
			position="absolute"
			display="block"
			font="--headline1"
			quarkly-title="Top"
		>
			<Override slot="SectionContent" lg-height="60px" display="none" />
		</Section>
		<Section
			top={0}
			height="70px"
			lg-height="60px"
			position="sticky"
			quarkly-title="SectionHeader"
			background="rgba(255, 255, 255, 0.95) linear-gradient(0deg,rgba(255, 255, 255, 0.2) 0.5%,#ffffff 100%)"
			z-index="2"
			lg-padding="10px 0 10px 0"
			font="--headline1"
		>
			<Override slot="SectionContent" lg-height="60px" />
			<Box
				flex-direction="row"
				md-flex-direction="row"
				md-display="flex"
				lg-padding="0 0 0 0"
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="center"
			>
				<Link
					md-display="inline-block"
					sm-width="60%"
					href="#top"
					height="80px"
					padding="0px 10px 0px 10px"
					md-padding="0px 10px 0px 0"
					md-width="50%"
					target="_self"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/logo_web.svg?v=2021-04-20T09:43:08.919Z) 0% 50% /auto no-repeat scroll padding-box"
					width="30%"
				/>
				<Components.BurgerMenu color="--text" font="--menu" lg-font="--menuMob" md-display="flex">
					<Override slot="menu" md-justify-content="start" font="--menu" color="--text">
						<Override
							slot="link"
							letter-spacing="normal"
							lg-font="--menuMob"
							md-font="--leadMob"
							font="--menu"
							color="--text"
						/>
						<Override slot="item-active" font="--menu" />
						<Override slot="link-operations" font="--menu" target="_self" href="#operations" />
						<Override slot="link-about-us" target="_self" href="#about" />
						<Override slot="link-certification" target="_self" href="#certification" />
						<Override slot="link-modality" target="_self" href="#modality" />
						<Override
							slot="item"
							text-transform="initial"
							font="--menu"
							color="--text"
							lg-font="--menuMob"
							lg-min-width="45px"
							min-width="65px"
						/>
						<Override slot="item-operations" font="--menu" color="--text" />
						<Override slot="link-contacts" target="_self" href="#contacts" />
						<Override slot="link-insurance" target="_self" href="#insurance" />
						<Override slot="item-privacypolicy" display="none" />
					</Override>
					<Override slot="menu-open" md-padding="80px 6px 6px 6px" md-justify-content="start" />
				</Components.BurgerMenu>
				{" "}
			</Box>
		</Section>
		<Section
			color="--light"
			font="--base"
			quarkly-title="Video"
			padding="0px 0 0px 0"
			md-margin="20px 0 0 0"
		>
			<Components.Wistia />
		</Section>
		<Section
			quarkly-title="Link About us"
			id="about"
			display="block"
			position="static"
			sm-margin="-60px 0 -60px 0"
			md-margin="-80px 0 -60px 0"
			height="100px"
			z-index="-1"
			margin="-120px 0 -30px 0"
		>
			<Override slot="SectionContent" height="120px" display="block" />
		</Section>
		<Section sm-padding="0px 0 40px 0" quarkly-title="About us">
			<Override slot="SectionContent" align-items="flex-start" />
			<Components.Animation test animation="← Slide In" iteration="once">
				<Text
					as="h2"
					font="--headline1"
					md-font="--headline3"
					margin="20px 0 0 0"
					text-align="left"
					align-self="flex-start"
					transition="left --transitionDuration-normal --transitionTimingFunction-easeOut 10s"
					sm-font="--headline3"
				>
					ABOUT THE COMPANY
				</Text>
			</Components.Animation>
			<Text
				margin="20px 0 0 0"
				width="80%"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				as="p"
				font="--base"
			>
				Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of our customers throughout Europe since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the 
high-quality
and low-cost service that we provide.
				<br />
				<br />
				Our industry-leading OTIF performance is testament to the quality and reliability of the carriers in our network, to the quality of our service and management of loads and also to our strong communication.
			</Text>
			<List
				font="--base"
				width="80%"
				sm-width="90%"
				md-width="95%"
				lg-width="95%"
			>
				<Text font="--base">
					Low-cost, but high-quality service with a very personalised approach to our customers and 
managing their loads.
				</Text>
				<Text font="--base">
					Low-cost solutions allow our rates to be very competitive meeting even the tightest customer budgets.
				</Text>
				<Text font="--base">
					Our network of over 3,000 trucks provide huge flexibility to our clients even for same day or next day loading. We load and deliver all over Europe.
				</Text>
				<Text font="--base">
					We are very flexible and can often create short term solutions to customer 
logistics
and delivery problems.
				</Text>
			</List>
		</Section>
		<Section padding="64px 0" sm-padding="40px 0" color="--dark" quarkly-title="REGIONAL DEVELOPMENT">
			<Components.Animation
				padding="0px 0px 40px 0px"
				test
				align-self="flex-start"
				iteration="once"
				animation="← Slide In"
			>
				<Text
					font="--headline1"
					md-font="--headline3"
					margin="20px 0 0 0"
					align-self="flex-start"
					as="h2"
				>
					OUR



REGIONAL DEVELOPMENT
				</Text>
			</Components.Animation>
			<Stack>
				<StackItem md-width="100%" width="100%">
					<Stack
						margin="-16px 0 -16px -16px"
						border-width="0 2px 0 0 "
						border-color="--color-text"
						border-style="solid"
						width="50%"
						sm-text-align="left"
					>
						<StackItem width="100%" sm-width="100%" quarkly-title="SL">
							<Override slot="StackItemContent" padding="100px 0px 0px 0px" flex-direction="column" />
							<Text
								margin="12px 0"
								lg-font="--leadMob"
								text-align="right"
								as="h3"
								font="--headline3"
							>
								Slovenia – 2017
							</Text>
							<StackItem display="flex" padding="0 0 0 0" width="100%">
								<Override slot="StackItemContent" flex-direction="row" justify-content="flex-end" />
								{"        "}
								<Icon
									category="bs"
									icon={BsCircleFill}
									color="--green"
									margin="-7px -3px 0px 0"
									z-index="1"
								/>
								<Hr
									border-color="--color-text"
									border-width="0 0 3px 0"
									margin="0px 0 16px 0"
									width="90%"
									padding="100p 0px 4px 0px"
								/>
								{"    "}
							</StackItem>
							<Image src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/koper_SL.jpg?v=2021-08-27T14:06:36.666Z" align-self="flex-end" loading="lazy" width="70%" />
							<Text
								text-align="right"
								width="100%"
								as="p"
								font="--base"
								margin="12px 0"
								color="--text"
								md-font="--baseMob"
							>
								Office
in Koper is a very
strong hub for eastern European destinations.
							</Text>
						</StackItem>
						<StackItem width="100%" sm-width="100%" quarkly-title="PL">
							<Override slot="StackItemContent" flex-direction="column" padding="0px 0px 0px 0px" />
							<Text
								text-align="right"
								as="h3"
								font="--headline3"
								margin="12px 0"
								lg-font="--leadMob"
							>
								Poland – 2013
							</Text>
							<StackItem padding="0 0 0 0" width="100%" display="flex">
								<Override slot="StackItemContent" justify-content="flex-end" />
								{"        "}
								<Icon
									category="bs"
									icon={BsCircleFill}
									color="--green"
									margin="-7px -3px 0px 0"
									z-index="1"
								/>
								<Hr
									border-color="--color-text"
									border-width="0 0 3px 0"
									width="90%"
									margin="0px 0 16px 0"
									padding="100p 0px 4px 0px"
								/>
								{"    "}
							</StackItem>
							<Image width="70%" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/square-4477850_640.jpg?v=2021-03-10T15:18:52.094Z" align-self="flex-end" loading="lazy" />
							<Text
								color="--text"
								md-font="--baseMob"
								width="90%"
								text-align="right"
								font="--base"
								margin="12px 0"
								sm-color="--text"
								align-self="flex-end"
								as="p"
								sm-font="--baseMob"
							>
								Kraków - The city of Polish
kings. This office ships throughout East
Europe and into West Europe.
							</Text>
						</StackItem>
					</Stack>
					<Stack
						width="50%"
						sm-text-align="left"
						margin="-16px -16px -16px 0"
						border-color="--color-text"
						border-style="solid"
						border-width="0 0 0 2px"
					>
						<StackItem width="100%" sm-width="100%" quarkly-title="NL">
							<Override slot="StackItemContent" flex-direction="column" />
							<Text as="h3" font="--headline3" margin="12px 0" lg-font="--leadMob">
								The Netherlands – 2018
							</Text>
							<Stack>
								{"    "}
								<StackItem display="flex" padding="0 0 0 0" width="100%">
									{"        "}
									<Hr border-color="--color-text" border-width="0 0 3px 0" margin="0px 0 16px 0" width="90%" />
									<Icon color="--green" margin="-7px 0px 0px -5px" category="bs" icon={BsCircleFill} />
									{"    "}
								</StackItem>
								{"    "}
							</Stack>
							<Image loading="lazy" width="70%" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/city-nl.jpg?v=2021-03-12T12:26:05.527Z" />
							<Text
								as="p"
								font="--base"
								margin="12px 0"
								color="--text"
								md-font="--baseMob"
								width="90%"
							>
								Our newest office & core to growth.
Voted European Capital of Culture.
Home to Europe’s largest port.
							</Text>
						</StackItem>
						<StackItem
							width="100%"
							sm-width="100%"
							quarkly-title="RU"
							overflow-x="visible"
							display="none"
						>
							<Override slot="StackItemContent" flex-direction="column" />
							<Text font="--headline3" margin="12px 0 0px 0" lg-font="--leadMob" as="h3">
								Russia – 2016
							</Text>
							<Stack>
								{"    "}
								<StackItem display="flex" padding="0 0 0 0" width="100%">
									{"        "}
									<Hr border-color="--color-text" border-width="0 0 3px 0" margin="0px 0 16px 0" width="90%" />
									<Icon icon={BsCircleFill} color="--green" margin="-7px 0px 0px -5px" category="bs" />
									{"    "}
								</StackItem>
								{"    "}
							</Stack>
							<Image width="70%" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/russia.jpg?v=2021-04-13T10:51:14.274Z" loading="lazy" />
							<Text
								as="p"
								font="--base"
								margin="12px 0"
								color="--text"
								sm-font="--baseMob"
								md-font="--baseMob"
								width="90%"
							>
								Offices
in St.Petersburg & Samara. Covers domestic and international shipments.
							</Text>
						</StackItem>
						<StackItem width="100%" sm-width="100%" quarkly-title="CZ">
							<Override slot="StackItemContent" flex-direction="column" />
							<Text margin="12px 0" lg-font="--leadMob" as="h3" font="--headline3">
								Czech Republic – 2010
							</Text>
							<Stack>
								{"    "}
								<StackItem padding="0 0 0 0" width="100%" display="flex">
									{"        "}
									<Hr border-color="--color-text" border-width="0 0 3px 0" margin="0px 0 16px 0" width="90%" />
									<Icon category="bs" icon={BsCircleFill} color="--green" margin="-7px 0px 0px -5px" />
									{"    "}
								</StackItem>
								{"    "}
							</Stack>
							<Image loading="lazy" width="70%" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/prague-4313160_640.jpg?v=2021-03-12T11:34:59.200Z" />
							<Text
								width="90%"
								as="p"
								font="--base"
								margin="12px 0"
								color="--text"
								md-font="--baseMob"
							>
								Our
head-quarters. Manages international
shipments and shipments into and around Russia
							</Text>
						</StackItem>
					</Stack>
				</StackItem>
			</Stack>
		</Section>
		<Section
			padding="64px 0"
			sm-padding="40px 0"
			font="--base"
			color="--fon"
			background="--color-fon"
		>
			<Stack margin-top="40px">
				<StackItem width="33%" lg-width="50%" sm-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Image align-self="center" width="100px" height="100px" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/1-02.svg?v=2021-03-24T11:08:03.466Z" />
					<Text
						align-self="center"
						text-align="center"
						as="h3"
						font="--headline3"
						margin="5px 0 20px 0"
						color="--fonText"
					>
						Large 
volume



 of trucks within our network
					</Text>
				</StackItem>
				<StackItem width="33%" lg-width="50%" sm-width="100%" sm-margin-top="24px">
					<Override slot="StackItemContent" flex-direction="column" />
					<Image width="100px" height="100px" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/2-02.svg?v=2021-03-24T11:08:03.472Z" align-self="center" />
					<Text
						align-self="center"
						sm-text-align="center"
						text-align="center"
						as="h3"
						font="--headline3"
						margin="5px 0 20px 0"
						color="--fonText"
					>
						Truck/train intermodal
					</Text>
				</StackItem>
				<StackItem width="33%" lg-width="50%" sm-width="100%" sm-margin-top="24px">
					<Override slot="StackItemContent" flex-direction="column" />
					<Image width="100px" height="100px" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/3-02.svg?v=2021-03-24T11:08:03.463Z" align-self="center" />
					<Text
						align-self="center"
						as="h3"
						font="--headline3"
						margin="5px 0 20px 0"
						color="--fonText"
					>
						Award winning
					</Text>
				</StackItem>
				<StackItem sm-margin-top="24px" width="33%" lg-width="50%" sm-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Image width="100px" height="100px" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/6-02.svg?v=2021-03-24T11:08:03.460Z" align-self="center" />
					<Text
						margin="5px 0 20px 0"
						color="--fonText"
						align-self="center"
						as="h3"
						font="--headline3"
					>
						0.05% claims
					</Text>
				</StackItem>
				<StackItem width="33%" lg-width="50%" sm-width="100%" sm-margin-top="24px">
					<Override slot="StackItemContent" flex-direction="column" />
					<Image src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/7-02.svg?v=2021-03-24T11:34:34.457Z" align-self="center" width="100px" height="100px" />
					<Text
						as="h3"
						font="--headline3"
						margin="5px 0 20px 0"
						color="--fonText"
						align-self="center"
					>
						High service levels
					</Text>
				</StackItem>
				<StackItem lg-width="50%" sm-width="100%" sm-margin-top="24px" width="33%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Image align-self="center" width="100px" height="100px" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/4-02.svg?v=2021-03-24T11:31:50.489Z" />
					<Text
						as="h3"
						font="--headline3"
						margin="5px 0 20px 0"
						color="--fonText"
						align-self="center"
					>
						Industry-leading OTIF
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section
			color="--dark"
			background="#f0f0f0"
			quarkly-title="Mission"
			padding="100px 0 100px 0"
			sm-padding="40px 0"
			font="--base"
		>
			<Override slot="SectionContent" width="100%" />
			<Stack margin-top="40px" width="100%" justify-content="space-evenly" margin="0 0 0 0">
				<StackItem
					lg-width="1000%"
					sm-width="100%"
					border-width="1px"
					border-style="solid"
					md-margin="0px 20px 10px 20px"
					lg-margin="0px 20px 10px 20px"
					width="30%"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Image
						sm-width="30%"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/li_web-02.svg?v=2021-03-04T13:38:32.028Z"
						align-self="center"
						width="50% content-box"
						height="50% content-box"
						padding="20px 0px 20px 0px"
						lg-width="20%"
					/>
					<Text as="h3" font="--headline2" margin="5px 0 20px 0" align-self="center">
						MISSION
					</Text>
					<Text
						font="--base"
						as="p"
						margin="20px 0 5px 0"
						align-self="center"
						text-align="center"
					>
						To provide our customers high-quality, reliable and on-time logistics solutions that are competitively priced and with a service level 
service
level that wins us awards.
					</Text>
				</StackItem>
				<StackItem
					sm-margin-top="24px"
					border-width="1px"
					border-style="solid"
					md-margin="0px 20px 10px 20px"
					lg-margin="0px 20px 10px 20px"
					width="30%"
					lg-width="100%"
					sm-width="100%"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Image
						width="50% content-box"
						height="50% content-box"
						padding="20px 0px 20px 0px"
						lg-width="20%"
						sm-width="30%"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/li_web-02.svg?v=2021-03-04T13:38:32.028Z"
						align-self="center"
					/>
					<Text as="h3" font="--headline2" margin="5px 0 20px 0" align-self="center">
						TEAM
					</Text>
					<Text as="p" margin="20px 0 5px 0" text-align="center" font="--base">
						Our low staff-turnover results from training and developing, treating people with respect and rewarding them well. This shows in the experienced and professional service levels our customers see every day.
					</Text>
				</StackItem>
				<StackItem
					sm-width="100%"
					md-margin="0px 20px 0px 20px"
					lg-margin="0px 20px 0px 20px"
					border-style="solid"
					sm-margin="20px 20px 0px 20px"
					width="30%"
					lg-width="100%"
					sm-margin-top="24px"
					border-width="1px"
				>
					<Override slot="StackItemContent" flex-direction="column" />
					<Image
						padding="20px 0px 20px 0px"
						lg-width="20%"
						sm-width="30%"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/li_web-02.svg?v=2021-03-04T13:38:32.028Z"
						align-self="center"
						width="50% content-box"
						height="50% content-box"
					/>
					<Text margin="5px 0 20px 0" align-self="center" as="h3" font="--headline2">
						PARTNERS
					</Text>
					<Text as="p" margin="20px 0 5px 0" text-align="center" font="--base">
						Venta-Trans Europe has a large network of trusted and thoroughly audited partners across Europe, giving us a large operational spread.  We employ a strict due diligence process to select our suppliers and only those carriers passing this process can work with us. We audit and select the best operators that really care about the performance of their business. This results in great partners that; are
reliable, care for their loads and project our customers’ brands in the best possible
way through the delivery process.
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Components.BgImageParallax
			imagePosition="center"
			imageRepeat="no-repeat"
			bgColor="rgba(255, 255, 255, 0.7)"
			z-index="0"
			imageURL="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/paralax.jpg?v=2021-04-22T13:59:12.767Z"
			imageSrc="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/paral1.jpg?v=2021-03-04T14:35:47.627Z"
			imageSize="100% auto"
			scrollInertiaProp="5"
			scrollSpeedProp="0.75"
		>
			<Override
				slot="Bground"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/paralax.jpg?v=2021-04-22T13:59:12.767Z) center/100% no-repeat scroll padding-box"
				md-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/paralax.jpg?v=2021-04-22T13:59:12.767Z) center/200% no-repeat scroll padding-box"
				lg-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/paralax.jpg?v=2021-04-22T13:59:12.767Z) center/150% no-repeat scroll padding-box"
				sm-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/paralax.jpg?v=2021-04-22T13:59:12.767Z) center/300% no-repeat scroll padding-box"
			/>
			<Section
				background="rgba(0, 77, 128, 0.6)"
				md-padding="150px 0 150px 0"
				text-align="center"
				background-color="--primary"
				color="rgba(0, 0, 0, 0)"
				padding="200px 0 200px 0"
				sm-padding="60px 0 60px 0"
				quarkly-title="Section_Insurance"
			>
				<Components.Animation iteration="once" animation="← Slide In" test>
					<Text
						text-align="left"
						as="h1"
						font="--headline1"
						md-font="--headline3"
						margin="10px 0 0 0"
						border-color="--color-fon"
						color="--fonText"
						align-self="flex-start"
					>
						PURPOSE
					</Text>
				</Components.Animation>
				<List color="#ffffff" font="normal 300 22px/1.5 --fontFamily-googleRoboto" margin="50px 0px 20px 0px">
					<Text font="--lead" color="#ffffff" text-align="left" margin="0 0px 0px 0px">
						Best in class service. We win awards for this.
					</Text>
					<Text font="--lead" color="#ffffff" text-align="left" margin="0 0px 0px 0px">
						Lowest quartile rates. Our rates are highly competitive. We operate in very rate-sensitive industries.
					</Text>
					<Text margin="0 0px 0 0px" font="--lead" color="#ffffff" text-align="left">
						Industry-leading OTIF performance. We supply on time.
					</Text>
					<Text text-align="left" margin="0 0px 0 0px" font="--lead" color="#ffffff">
						Best value for such high-quality service. Industry leading, often copied.
					</Text>
					<Text color="#ffffff" text-align="left" margin="0 0px 0 0px" font="--lead">
						Highly trusted, reliable and very trustworthy carriers that care for the loads and do not take risks with them.
					</Text>
					<Text color="#ffffff" text-align="left" margin="0 0px 0 0px" font="--lead">
						High and lower volume truck services.
					</Text>
				</List>
			</Section>
		</Components.BgImageParallax>
		<Section
			padding="100px 0 100px 0"
			sm-padding="40px 0"
			font="--base"
			color="--dark"
			quarkly-title="Objectives"
			background="#004d80"
		>
			<Components.Animation iteration="once" animation="← Slide In" test>
				<Text
					margin="0 auto 5px 0"
					align-self="flex-start"
					sm-font="--headline3"
					as="h1"
					md-font="--headline3"
					max-width="520px"
					text-align="left"
					color="--fonTextInver"
					font="--headline1"
				>
					OBJECTIVES
				</Text>
			</Components.Animation>
			<Stack margin-top="40px">
				<StackItem width="25%" lg-width="50%" sm-width="100%">
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Image
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/3-02.svg?v=2021-03-04T15:09:22.081Z"
						margin="0px 0px 20px 0px"
						width="100px"
						height="100px"
						display="block"
						align-self="auto"
					/>
					<Text
						font="--headline3"
						margin="5px 0 8px 0"
						text-align="center"
						color="--fonTextInver"
						as="h3"
					>
						Timely & Reliable Response
					</Text>
					<Text
						color="--fonTextInver"
						as="p"
						margin="0px 0 5px 0"
						text-align="center"
						font="--base"
					>
						customers trust us to act and react rapidly, to solve any problems that emerge and get their materials to their destination on time and without drama.
					</Text>
				</StackItem>
				<StackItem
					width="25%"
					lg-width="50%"
					sm-width="100%"
					sm-margin-top="24px"
					sm-text-align="center"
				>
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Image
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/2-02-02.svg?v=2021-03-04T15:06:37.656Z"
						margin="0px 0px 20px 0px"
						width="100px"
						height="100px"
						display="block"
						align-self="auto"
					/>
					<Text
						text-align="center"
						as="h3"
						font="--headline3"
						margin="5px 0 20px 0"
						color="--fonTextInver"
						sm-text-align="center"
					>
						Planning and Control
					</Text>
					<Text
						as="p"
						margin="20px 0 5px 0"
						text-align="center"
						font="--base"
						color="--fonTextInver"
					>
						at all times allowing smooth processing and management of cargo.  Timing is critical, control is paramount.
					</Text>
				</StackItem>
				<StackItem width="25%" lg-width="50%" sm-width="100%" sm-margin-top="24px">
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Image
						display="block"
						align-self="auto"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/4-02.svg?v=2021-03-04T15:09:46.921Z"
						margin="0px 0px 20px 0px"
						width="100px"
						height="100px"
					/>
					<Text
						color="--fonTextInver"
						text-align="center"
						as="h3"
						font="--headline3"
						margin="5px 0 20px 0"
					>
						Risk Management
					</Text>
					<Text
						font="--base"
						color="--fonTextInver"
						as="p"
						margin="20px 0 5px 0"
						text-align="center"
					>
						minimise any possible risk for customer loads & ensure the correct cover and process controls are in place if problems occur.
					</Text>
				</StackItem>
				<StackItem width="25%" lg-width="50%" sm-width="100%" sm-margin-top="24px">
					<Override slot="StackItemContent" flex-direction="column" text-align="center" align-items="center" />
					<Image
						height="100px"
						display="block"
						align-self="auto"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/plan-02.svg?v=2021-03-04T15:10:04.609Z"
						margin="0px 0px 20px 0px"
						width="100px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" color="--fonTextInver">
						Partnerships
					</Text>
					<Text as="p" margin="20px 0 5px 0" color="--fonTextInver">
						the best supplier is a silent supplier.  Noise-free, fluid & timely processing of loads



 for our customers. A trustworthy & reliable partner.  That is Venta-Trans.
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section
			display="block"
			padding="0px 0 0px 0"
			sm-padding="0px 0 0px 0"
			quarkly-title="Link modality"
			id="modality"
		>
			<Override slot="SectionContent" height="120px" display="block" />
		</Section>
		<Section
			padding="0px 0 20px 0"
			sm-padding="0px 0 40px 0"
			quarkly-title="Modality"
			align-items="flex-start"
			md-padding="0px 0 25px 0"
			lg-padding="0px 0 25px 0"
		>
			<Override slot="SectionContent" align-items="flex-start" justify-content="left" />
			<Components.Animation iteration="once" animation="← Slide In" test>
				<Text
					sm-font="--headline3"
					as="h2"
					font="--headline1"
					md-font="--headline3"
					margin="20px 0 0 0"
					text-align="left"
					align-self="auto"
				>
					MODALITY
				</Text>
			</Components.Animation>
			<Text
				md-width="100%"
				lg-width="100%"
				as="p"
				font="--base"
				margin="20px 0 0 0"
				width="80%"
				align-self="auto"
				sm-width="100%"
			>
				Trucks are our core business and we have
a large network that covers most places through Europe.  We mostly provide FTL services, but also provide multi-drop and intermodal services for our customers.
				<br />
				<br />
				We have over 3,000 trucks in our trusted and audited network to safely deliver cargo for our customers.
				<br />
				<br />
				We offer FTL services in most vehicle
formats and we can carry most types of cargo, including, in some cases,
hazardous and waste materials.  Although
we specialise in shipping metals, we also ship for customers within many other
segments, like:
			</Text>
			<List
				font="--base"
				width="80%"
				sm-width="90%"
				md-width="95%"
				lg-width="95%"
				margin="10px 0px 10px 0px"
			>
				<Text font="--base" margin="10px 0px 10px 0px">
					Metals: aluminium, steel, minerals, waste aluminium
				</Text>
				<Text font="--base" margin="0 0px 10px 0px">
					Building and construction
				</Text>
				<Text font="--base" margin="0 0px 10px 0px">
					FMCG products
				</Text>
				<Text font="--base" margin="10px 0px 10px 0px">
					Consumer durables
				</Text>
				<Text font="--base">
					Plastics and plastic raw materials
				</Text>
				<Text font="--base">
					Chemicals and hazardous materials
				</Text>
				<Text font="--base">
					Solar panels
				</Text>
				<Text font="--base">
					Paper and pulp products
				</Text>
			</List>
			<Text
				md-width="100%"
				lg-width="100%"
				as="p"
				font="--base"
				margin="0px 0 0 0"
				width="80%"
				align-self="auto"
				sm-width="100%"
			>
				Most of our loads are point to point, but
our multi-drop service allows delivery to multiple locations that are not too
far from each other. We can also offer
LTL in some cases depending on the location, although this is not our core
competence. Please contact us for
further details.
			</Text>
		</Section>
		<Section
			md-padding="25px 0 50px 0"
			lg-padding="25px 0 50px 0"
			padding="0px 0 50px 0"
			sm-padding="40px 0"
			font="--base"
			color="--dark"
			quarkly-title="modality car"
		>
			<Stack>
				<StackItem width="100%" lg-width="100%" lg-order="2">
					<Override slot="StackItemContent" text-align="center" />
					<Stack>
						<StackItem width="50%" sm-width="100%">
							<Override slot="StackItemContent" flex-direction="column" padding="20px" />
							<Image
								width="50%"
								height="200px"
								src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/van_web-02.svg?v=2021-03-10T11:48:25.264Z"
								align-self="center"
								loading="lazy"
							/>
							<Text
								as="h3"
								font="--headline2"
								margin="20px 0 5px 0"
								sm-font="--h3Mob22600"
								md-font="--h3Mob22600"
							>
								VAN
							</Text>
						</StackItem>
						<StackItem sm-width="100%" width="50%">
							<Override slot="StackItemContent" padding="20px" flex-direction="column" />
							<Image width="100%" height="200px" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/TAUTLINER%20COILMULDE_web-02.svg?v=2021-03-10T11:22:59.090Z" loading="lazy" />
							<Text
								as="h3"
								font="--headline2"
								margin="20px 0 5px 0"
								sm-font="--h3Mob22600"
								md-width="100%"
								md-font="--h3Mob22600"
							>
								TAUTLINER, COILMULDE, TIPPER, WALKING PLATFORM, BOX
							</Text>
						</StackItem>
						<StackItem width="50%" sm-width="100%">
							<Override slot="StackItemContent" flex-direction="column" padding="20px" />
							<Image
								width="90%"
								height="200px"
								src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/mega_web-02.svg?v=2021-03-10T11:33:19.588Z"
								margin="10px 0px 0px 0px"
								loading="lazy"
							/>
							<Text
								font="--headline2"
								margin="20px 0 5px 0"
								sm-font="--h3Mob22600"
								md-font="--h3Mob22600"
								as="h3"
							>
								MEGA
							</Text>
						</StackItem>
						<StackItem width="50%" sm-width="100%">
							<Override slot="StackItemContent" padding="20px" flex-direction="column" />
							<Image src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/JUMBO_web-02.svg?v=2021-03-10T11:41:19.959Z" loading="lazy" width="100%" height="210px" />
							<Text
								font="--headline2"
								margin="20px 0 5px 0"
								sm-font="--h3Mob22600"
								md-font="--h3Mob22600"
								as="h3"
							>
								JUMBO SET
							</Text>
						</StackItem>
					</Stack>
				</StackItem>
			</Stack>
		</Section>
		<Section
			padding="50px 0 50px 0"
			sm-padding="40px 0"
			quarkly-title="intermodal"
			id="about"
			align-items="flex-start"
		>
			<Override slot="SectionContent" align-items="flex-start" justify-content="left" />
			<Components.Animation iteration="once" test animation="← Slide In">
				<Text
					align-self="flex-start"
					sm-font="--headline3"
					as="h2"
					font="--headline1"
					md-font="--headline3"
					margin="20px 0 0 0"
					text-align="left"
				>
					INTERMODAL
				</Text>
			</Components.Animation>
			<Text
				lg-width="100%"
				as="p"
				font="--base"
				margin="20px 0 0 0"
				width="80%"
				align-self="auto"
				sm-width="100%"
				md-width="100%"
			>
				Can reduce harmful CO2 emissions, a big target for Venta Trans and something we take very seriously.{" "}
				<br />
				<br />
				A great way to ship FTL freight long distances without road congestion & traffic delays. The environmentally friendly approach. A
good way to reduce the carbon footprint of your loads.
				<br />
				<br />
				Example of current intermodal:
			</Text>
			<List
				lg-width="80%"
				font="--base"
				margin="10px 0px 20px 0px"
				sm-width="90%"
				md-width="90%"
			>
				<Text font="--base">
					Rotterdam: Trailer from warehouse loaded onto train{" "}
				</Text>
				<Text font="--base">
					Rotterdam -&gt; Poland by train
				</Text>
				<Text font="--base">
					Poland -&gt; to final destination by truck
				</Text>
				<Text font="--base">
					Transit time A-C or A-D
				</Text>
			</List>
			<Image
				margin="40px 0px 0px 0px"
				sm-width="100%"
				sm-height="auto"
				loading="lazy"
				width="500px"
				height="200px"
				src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/intermodal-02.svg?v=2021-03-18T10:01:15.339Z"
				align-self="flex-start"
			/>
			<Section
				quarkly-title="Link certification"
				id="certification"
				display="block"
				padding="0 0 20px 0"
				sm-padding="0px 0 0px 0"
			>
				<Override slot="SectionContent" display="block" />
			</Section>
		</Section>
		<Section quarkly-title="certification" background="#f0f0f0" sm-padding="24px 0 0 0">
			<Override slot="SectionContent" align-items="flex-start" justify-content="left" />
			<Components.Animation iteration="once" animation="← Slide In" test>
				<Text
					text-align="left"
					align-self="flex-start"
					sm-font="--headline3"
					as="h2"
					font="--headline1"
					md-font="--headline3"
					margin="20px 0 0 0"
				>
					QUALITY ASSURANCE & ISO CERTIFICATION
				</Text>
			</Components.Animation>
			<Stack sm-margin="0 -16px -16px -16px" md-margin="0px -16px -16px -16px" align-items="center">
				{"    "}
				<StackItem
					lg-width="100%"
					width="60%"
					display="flex"
					sm-width="100%"
					sm-margin="0px 0px 0px 0px"
					md-width="100%"
				>
					{"        "}
					<Text font="--base" margin="0px 0px 0px 0px" display="inline-block" width="100%">
						We take Quality and 
Health
& Safety



 very seriously. Before we start to work with a carrier we take them through a lengthy due diligence process, which includes, in part, their commitment to the provision of quality through their business and operation. They must project the core values and high quality of service we offer to our clients so these are reflected throughout the delivery process. 
We
often partner with



 smaller carriers that we know well and are guaranteed to be reliable and trustworthy, people who really care for their business and our loads. This means our customers’ cargo is always well cared for throughout the process to ensure the safety and security of the load and the drivers.
						<br />
						<br />
						The high quality of our service and low rates have allowed us to build the business across Europe,
building
our client base and extending the destinations, segments we cover and cargo we
carry.
						<br />
						<br />
						We are accredited with ISO 9001 and ISO 27001{"            "}
					</Text>
					{"    "}
				</StackItem>
				<StackItem
					md-width="100%"
					md-margin="0px 0px 0px 0"
					lg-width="100%"
					width="40%"
					display="flex"
					sm-width="100%"
					sm-height="auto"
					sm-margin="0px 0px 0px 0"
				>
					{"        "}
					<Image
						loading="lazy"
						width="50%"
						height="350px"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/Certifika%CC%81t%20ISO%2027001_eng.jpg?v=2021-04-22T11:57:51.725Z"
						align-self="auto"
						margin="200px 0px 0px 0px"
						padding="0px 20px 0px 0px"
						lg-margin="0 0px 0px 0px"
					/>
					<Image
						height="350px"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/Certifikat%20ISO%209001_eng.jpg?v=2021-04-22T11:57:51.723Z"
						align-self="auto"
						loading="lazy"
						width="50%"
					/>
					{"    "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Section
			quarkly-title="Link insurance"
			id="insurance"
			display="block"
			background="#f0f0f0"
			padding="0px 0 0px 0"
			sm-margin="-60px 0 0 0"
		>
			<Override slot="SectionContent" height="120px" display="block" />
		</Section>
		<Components.BgImageParallax
			imageRepeat="no-repeat"
			bgColor="rgba(255, 255, 255, 0.7)"
			imageURL="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/parallax2.jpeg?v=2021-04-07T17:46:59.514Z"
			scrollSpeedProp="0.75"
			z-index="0"
			imageSize="150%"
			imageSrc="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/paral1.jpg?v=2021-03-04T14:35:47.627Z"
			imagePosition="center"
			scrollInertiaProp="5"
			width="100%"
		>
			<Override
				slot="Bground"
				sm-min-width="100%"
				sm-min-height="100%"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/parallax2.jpeg?v=2021-04-07T17:46:59.514Z) center/150% no-repeat scroll padding-box"
				sm-background="rgba(0, 0, 0, 0) url(&quot;https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/parallax4.jpg?v=2021-04-16T13:06:46.775Z&quot;) center/550% no-repeat scroll padding-box"
				lg-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/parallax2.jpeg?v=2021-04-07T17:46:59.514Z) center/200% no-repeat scroll padding-box"
				md-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/parallax2.jpeg?v=2021-04-07T17:46:59.514Z) center/320% no-repeat scroll padding-box"
			/>
			<Section
				md-padding="150px 0 150px 0"
				text-align="center"
				background-color="--primary"
				color="rgba(0, 0, 0, 0)"
				padding="200px 0 200px 0"
				sm-padding="80px 0 80px 0"
				quarkly-title="Section_Insurance"
				background="rgba(0, 77, 128, 0.6)"
			>
				<Components.Animation iteration="once" animation="← Slide In" test>
					<Text
						align-self="flex-start"
						text-align="left"
						as="h1"
						font="--headline1"
						md-font="--headline3"
						margin="10px 0 0 0"
						border-color="--color-fon"
						color="--fonText"
					>
						INSURANCE
					</Text>
				</Components.Animation>
				<Text
					align-self="flex-start"
					text-align="left"
					sm-font="--leadMob"
					as="p"
					font="--lead"
					margin="40px 0 20px 0"
					color="--fonText"
				>
					Venta-Trans Europe is insured with KRAVAG-LOGISTIC Versicherungs-AG,
					<br />
					with an insurance coverage of € 10 million
				</Text>
			</Section>
		</Components.BgImageParallax>
		<Section
			quarkly-title="Link operations"
			id="operations"
			display="block"
			background="#fff"
			margin="-50px 0 0 0"
			padding="0px 0 0px 0"
			sm-padding="0px 0 0px 0"
		>
			<Override slot="SectionContent" height="120px" display="block" />
		</Section>
		<Section
			padding="0px 0 64px 0"
			sm-padding="0px 0 40px 0"
			font="--base"
			color="--dark"
			quarkly-title="OPERATIONS"
			sm-flex-direction="column"
		>
			<Stack margin="0px -16px 0 -16px">
				<StackItem width="50%" lg-width="100%" margin="0px 0px 0px 0px">
					<Override slot="StackItemContent" lg-margin-bottom="24px" flex-direction="column" />
					<Components.Animation iteration="once" animation="← Slide In" test>
						<Text
							as="h2"
							font="--headline1"
							md-font="--headline3"
							margin="0"
							sm-font="--headline3"
						>
							OUR OPERATIONS
						</Text>
					</Components.Animation>
				</StackItem>
			</Stack>
			<Stack margin="10px -16px -16px -16px" quarkly-title="Titul NL">
				<StackItem lg-width="100%" margin="0px 0px 00px 0px" width="50%">
					<Override slot="StackItemContent" lg-margin-bottom="24px" flex-direction="column" />
					<Components.Animation iteration="once" animation="← Slide In" test>
						<Text
							md-font="--lead"
							margin="0"
							lg-font="--headline3"
							text-transform="uppercase"
							as="h2"
							font="--headline3"
						>
							The Netherlands
						</Text>
					</Components.Animation>
				</StackItem>
			</Stack>
			<Stack margin="5px -16px 5px -16px" quarkly-title="Stack NL">
				{"    "}
				<StackItem
					width="45%"
					display="block"
					lg-width="50%"
					sm-width="100%"
					sm-height="100%"
				>
					<Override slot="StackItemContent" background="--color-fon" padding="20px 20px 20px 20px" flex-direction="column" />
					{"        "}
					<Stack>
						{"    "}
						<StackItem width="20%">
							<Override slot="StackItemContent" background="--color-fon" align-items="center" />
							{"        "}
							<Text
								transform="rotate(270deg)"
								position="relative"
								md-bottom="-160px"
								font="600 3em roboto"
								color="--green"
								sm-font="600 2.5em roboto"
								text-align="center"
								bottom="-140px"
								sm-bottom="-120px"
							>
								ROTTERDAM
							</Text>
							{"    "}
						</StackItem>
						{"    "}
						<StackItem md-padding="16px 0 16px 16px" width="75%" display="flex">
							<Text
								font="--lead"
								display="block"
								color="#ffffff"
								sm-font="--baseMob"
								lg-font="--leadMob"
								md-font="--baseMob"
							>
								Rotterdam is our newest office and 
manages
loads within Western and Central Europe 



 and is core to the growth of Venta-Trans.
								<br />
								<br />
								– Industry-leading OTIF
								<br />
								– >3 000 trucks
								<br />
								– Customs documents
								<br />
								– 99.5% driver arrival accuracy
								<br />
								– 0.1% driver delays
								<br />
								<br />
								Rotterdam has previously been voted as European Capital of Culture.  It is home to one of the world’s largest ports and also to 170 different nationalities.
								<br />
								{"            "}
							</Text>
							{"            "}
						</StackItem>
					</Stack>
					<Image
						loading="lazy"
						width="100%"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/city-nl.jpg?v=2021-03-12T12:26:05.527Z"
						sm-width="100%"
						sm-height="auto"
						lg-width="100%"
						margin="20px 0px 0px 0px"
						display="block"
						align-self="auto"
					/>
					{"    "}
				</StackItem>
				{"    "}
				<StackItem
					width="55%"
					display="flex"
					sm-width="100%"
					sm-height="100% "
					lg-width="50%"
				>
					{"        "}
					<Image height="100%" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/nl_maps-01.svg?v=2023-11-14T19:00:38.889Z" loading="lazy" width="100%" />
					{"    "}
				</StackItem>
			</Stack>
			<Stack margin="40px -16px -16px -16px" quarkly-title="Titul PL">
				<StackItem width="50%" lg-width="100%" margin="0px 0px 00px 0px">
					<Override slot="StackItemContent" lg-margin-bottom="24px" flex-direction="column" />
					<Components.Animation iteration="once" animation="← Slide In" test>
						<Text
							lg-font="--headline3"
							as="h2"
							font="--headline3"
							md-font="--lead"
							margin="0"
						>
							POLISH OPERATION
						</Text>
					</Components.Animation>
				</StackItem>
			</Stack>
			<Stack sm-flex-direction="column" margin="0px -16px 20px -16px" md-justify-content="space-between" quarkly-title="Stack PL">
				{"    "}
				<StackItem
					lg-width="50%"
					sm-width="100%"
					sm-height="100%"
					width="45%"
					display="block"
				>
					<Override slot="StackItemContent" flex-direction="column" background="--color-fon" padding="20px 20px 20px 20px" />
					{"        "}
					<Stack>
						{"    "}
						<StackItem width="20%">
							<Override slot="StackItemContent" background="--color-fon" align-items="center" />
							{"        "}
							<Text
								position="relative"
								bottom="-100px"
								sm-bottom="-70px"
								font="600 3em roboto"
								color="--green"
								sm-font="600 2.5em roboto"
								transform="rotate(270deg)"
								text-align="center"
							>
								KRAKOW
							</Text>
							{"    "}
						</StackItem>
						{"    "}
						<StackItem width="75%" display="flex" md-padding="16px 0 16px 16px">
							<Text
								font="--lead"
								display="block"
								color="#ffffff"
								sm-font="--baseMob"
								lg-font="--leadMob"
								md-font="--baseMob"
							>
								Kraków - The city of Polish kings. Krakow is the trade center of Slavic Europe. This Eastern Europe and into Western Europe.
								<br />
								<br />
								– Industry-leading OTIF
								<br />
								– >3 000 trucks
								<br />
								– 99.5% driver arrival accuracy
								<br />
								– 0.1% driver delays
								<br />
								<br />
								One of the most vibrant commercial and cultural centers in Eastern Europe.
							</Text>
							{"            "}
						</StackItem>
					</Stack>
					<Image
						sm-width="100%"
						sm-height="auto"
						width="100%"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/square-4477850_640.jpg?v=2021-03-10T15:18:52.094Z"
						align-self="auto"
						margin="20px 0px 0px 0px"
						display="block"
						lg-width="100%"
						loading="lazy"
					/>
					{"    "}
				</StackItem>
				{"    "}
				<StackItem
					width="55%"
					display="flex"
					sm-width="100%"
					sm-height="100% "
					md-width="50%"
					lg-width="50%"
				>
					{"        "}
					<Image width="100%" height="100%" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/pl-maps-01.svg?v=2023-11-14T19:00:38.887Z" loading="lazy" />
					{"    "}
				</StackItem>
			</Stack>
			<Stack margin="40px -16px -16px -16px" quarkly-title="Titul CZ">
				<StackItem width="50%" lg-width="100%" margin="0px 0px 00px 0px">
					<Override slot="StackItemContent" lg-margin-bottom="24px" flex-direction="column" />
					<Components.Animation iteration="once" animation="← Slide In" test>
						<Text
							as="h2"
							font="--headline3"
							md-font="--lead"
							margin="0"
							lg-font="--headline3"
						>
							CZECH OPERATION
						</Text>
					</Components.Animation>
				</StackItem>
			</Stack>
			<Stack quarkly-title="Stack CZ" sm-flex-direction="column" margin="5px -16px 5px -16px">
				{"    "}
				<StackItem
					width="45%"
					display="block"
					lg-width="50%"
					sm-width="100%"
					sm-height="100%"
				>
					<Override slot="StackItemContent" padding="20px 20px 20px 20px" flex-direction="column" background="--color-fon" />
					{"        "}
					<Stack>
						{"    "}
						<StackItem width="20%">
							<Override slot="StackItemContent" align-items="center" background="--color-fon" />
							{"        "}
							<Text
								transform="rotate(270deg)"
								text-align="center"
								position="relative"
								bottom="-100px"
								sm-bottom="-70px"
								font="600 3em roboto"
								color="--green"
								sm-font="600 2.5em roboto"
							>
								PRAGUE
							</Text>
							{"    "}
						</StackItem>
						{"    "}
						<StackItem width="75%" display="flex" md-padding="16px 0 16px 16px">
							<Text
								font="--lead"
								display="block"
								color="#ffffff"
								sm-font="--baseMob"
								lg-font="--leadMob"
								md-font="--baseMob"
							>
								The city of dreams lies at the heart of Europe and is the centre of the Venta-Trans empire.
								<br />
								<br />
								– Industry-leading OTIF
								<br />
								– >3 000 trucks
								<br />
								– 99.5% driver arrival accuracy
								<br />
								– 0.1% driver delays
								<br />
								<br />
								Prague is the 4th most visited city in Europe.  It is nicknamed “the City of a Hundred Spires” and is home to the largest castle in the world.
							</Text>
							{"            "}
						</StackItem>
					</Stack>
					<Image
						align-self="auto"
						sm-width="100%"
						sm-height="auto"
						margin="20px 0px 0px 0px"
						width="100%"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/prague-4313160_640.jpg?v=2021-03-12T11:34:59.200Z"
						display="block"
						lg-width="100%"
						loading="lazy"
					/>
					{"    "}
				</StackItem>
				{"    "}
				<StackItem
					sm-height="100% "
					md-width="50%"
					lg-width="50%"
					width="55%"
					display="flex"
					sm-width="100%"
				>
					{"        "}
					<Image loading="lazy" width="100%" height="100%" src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/cz-maps-01.svg?v=2023-11-14T19:05:57.246Z" />
					{"    "}
				</StackItem>
			</Stack>
			<Stack margin="40px -16px -16px -16px" quarkly-title="Titul SLO">
				<StackItem width="50%" lg-width="100%" margin="0px 0px 00px 0px">
					<Override slot="StackItemContent" flex-direction="column" lg-margin-bottom="24px" />
					<Components.Animation iteration="once" animation="← Slide In" test>
						<Text
							as="h2"
							font="--headline3"
							md-font="--lead"
							margin="0"
							lg-font="--headline3"
							text-transform="uppercase"
						>
							Slovenia
						</Text>
					</Components.Animation>
				</StackItem>
			</Stack>
			<Stack margin="5px -16px 5px -16px" quarkly-title="Stack SLO" sm-flex-direction="column">
				{"    "}
				<StackItem
					display="block"
					lg-width="50%"
					sm-width="100%"
					sm-height="100%"
					width="45%"
				>
					<Override slot="StackItemContent" background="--color-fon" padding="20px 20px 20px 20px" flex-direction="column" />
					{"        "}
					<Stack>
						{"    "}
						<StackItem width="20%">
							<Override slot="StackItemContent" background="--color-fon" align-items="center" />
							{"        "}
							<Text
								sm-bottom="-70px"
								font="600 3em roboto"
								color="--green"
								sm-font="600 2.5em roboto"
								transform="rotate(270deg)"
								text-align="center"
								position="relative"
								bottom="-100px"
							>
								KOPER
							</Text>
							{"    "}
						</StackItem>
						{"    "}
						<StackItem width="75%" display="flex" md-padding="16px 0 16px 16px">
							<Text
								font="--lead"
								display="block"
								color="#ffffff"
								sm-font="--baseMob"
								lg-font="--leadMob"
								md-font="--baseMob"
							>
								Slovenia is very proud that one of its own holds the World record for freediving to a depth of 114m.
								<br />
								<br />
								– Industry-leading OTIF
								<br />
								– >3 000 trucks
								<br />
								– 99.5% driver arrival accuracy
								<br />
								– 0.1% driver delays
								<br />
								<br />
								Slovenia is home to the world’s highest ski jumping centre, where many world record jumps have been recorded. It is also home to the world’s steepest zip line descent.
							</Text>
							{"            "}
						</StackItem>
					</Stack>
					<Image
						margin="20px 0px 0px 0px"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/koper_SL.jpg?v=2021-08-27T14:06:36.666Z"
						display="block"
						align-self="auto"
						sm-width="100%"
						sm-height="auto"
						lg-width="100%"
						loading="lazy"
						width="100%"
					/>
					{"    "}
				</StackItem>
				{"    "}
				<StackItem
					width="55%"
					display="flex"
					sm-width="100%"
					sm-height="100% "
					md-width="50%"
					lg-width="50%"
				>
					{"        "}
					<Image src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/so_maps-01.svg?v=2023-11-14T19:00:38.893Z" loading="lazy" width="100%" height="100%" />
					{"    "}
				</StackItem>
			</Stack>
			<Stack margin="40px -16px -16px -16px" quarkly-title="Titul RU" display="none">
				<StackItem margin="0px 0px 00px 0px" width="50%" lg-width="100%">
					<Override slot="StackItemContent" lg-margin-bottom="24px" flex-direction="column" />
					<Components.Animation test iteration="once" animation="← Slide In">
						<Text
							margin="0"
							lg-font="--headline3"
							text-transform="uppercase"
							as="h2"
							font="--headline3"
							md-font="--lead"
						>
							Russia
						</Text>
					</Components.Animation>
				</StackItem>
			</Stack>
			<Stack sm-flex-direction="column" margin="5px -16px 5px -16px" quarkly-title="Stack RU" display="none">
				{"    "}
				<StackItem
					width="45%"
					display="block"
					lg-width="50%"
					sm-width="100%"
					sm-height="100%"
				>
					<Override slot="StackItemContent" background="--color-fon" padding="20px 20px 20px 20px" flex-direction="column" />
					{"        "}
					<Stack>
						{"    "}
						<StackItem width="20%">
							<Override slot="StackItemContent" background="--color-fon" align-items="center" />
							{"        "}
							<Text
								transform="rotate(270deg)"
								position="relative"
								left="-150px"
								font="600 3em roboto"
								color="--green"
								height="71px"
								min-width="350px"
								md-left="-160px"
								sm-left="-150px"
								sm-font="600 2.5em roboto"
								text-align="center"
							>
								2X OFFICES
							</Text>
							{"    "}
						</StackItem>
						{"    "}
						<StackItem width="75%" display="flex" md-padding="16px 0 16px 16px">
							<Text
								md-font="--baseMob"
								font="--lead"
								display="block"
								color="#ffffff"
								sm-font="--baseMob"
								lg-font="--leadMob"
							>
								Our offices in St.Petersburg & Samara manage logistics throughout Russia and also into Eastern Europe.
								<br />
								<br />
								– Industry-leading OTIF
								<br />
								– 0.1% damage claims
								<br />
								– 99.5% driver arrival accuracy
								<br />
								– 0.1% driver delays
								<br />
								<br />
								10% of the total area of St.Petersburg is covered with water and is often known as the North Venice.
							</Text>
							{"            "}
						</StackItem>
					</Stack>
					<Image
						lg-width="100%"
						align-self="auto"
						sm-width="100%"
						sm-height="auto"
						loading="lazy"
						margin="20px 0px 0px 0px"
						width="100%"
						src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/russia.jpg?v=2021-04-13T10:51:14.274Z"
						display="block"
					/>
					{"    "}
				</StackItem>
				{"    "}
				<StackItem
					width="55%"
					display="flex"
					sm-width="100%"
					sm-height="100% "
					md-width="50%"
					lg-width="50%"
				>
					{"        "}
					<Image src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/ru_maps-01.svg?v=2021-03-12T14:06:19.505Z" width="100%" height="100%" />
					{"    "}
				</StackItem>
			</Stack>
		</Section>
		<Section
			sm-padding="0px 0 0px 0"
			quarkly-title="Link contacts"
			id="contacts"
			display="block"
			background="#fff"
			height="60px"
			padding="0px 0 0px 0"
		>
			<Override slot="SectionContent" height="120px" display="block" />
		</Section>
		<Section
			padding="50px 0 50px 0"
			sm-padding="40px 0"
			position="relative"
			background="--color-fon"
			quarkly-title="contacts form"
			color="--light"
		>
			<Components.Animation iteration="once" animation="← Slide In" md-font="--headline3" test>
				<Text font="--headline1" md-font="--headline3" color="--fonText">
					CONTACTS
				</Text>
			</Components.Animation>
			<Stack>
				<StackItem width="60%" md-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						padding="0 0 0 40px"
						margin="32px 0 0 0"
						position="relative"
						color="--text2"
						width="90%"
						sm-width="80%"
					>
						<Icon
							height="32px"
							width="32px"
							color="--green"
							top="0"
							category="fa"
							icon={FaMapPin}
							position="absolute"
							size="32px"
							left="0"
						/>
						<Text
							as="p"
							margin="6px 0 6px 0"
							font="--lead"
							width="100%"
							color="--fonText"
						>
							Rotterdam, Netherlands
						</Text>
						<Box lg-flex-direction="column" display="flex" justify-content="left">
							<Link
								text-decoration-line="initial"
								color="--fonText"
								display="flex"
								align-items="center"
								margin="0px 10px 0px 0px"
								href="tel:+31615497723"
								font="--base"
							>
								<Strong
									font="--lead"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
								>
									tel.:{" "}
								</Strong>
								+31 6 15 49 77 23{" "}
							</Link>
							<Link
								href="mailto:info@ventatrans.nl"
								font="--base"
								text-decoration-line="initial"
								color="--fonText"
								display="flex"
								align-items="center"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									font="--lead"
								>
									e-mail:{" "}
								</Strong>
								info@ventatrans.nl
							</Link>
						</Box>
					</Box>
					<Box
						padding="0 0 0 40px"
						margin="32px 0 0 0"
						max-width="none"
						position="relative"
						color="--text2"
						width="90%"
						sm-width="80%"
					>
						<Icon
							size="32px"
							icon={FaMapPin}
							width="32px"
							height="32px"
							color="--green"
							position="absolute"
							top="0"
							left="0"
							category="fa"
						/>
						<Text
							as="p"
							margin="6px 0"
							font="--lead"
							sm-font="--leadMob"
							width="100%"
							color="--fonText"
						>
							Krakow, Poland
						</Text>
						<Box display="flex" justify-content="left" lg-flex-direction="column">
							<Link
								color="--fonText"
								display="flex"
								align-items="center"
								margin="0px 10px 0px 0px"
								href="tel:+48668619007"
								font="--base"
								text-decoration-line="initial"
							>
								<Strong
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									font="--lead"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									user-select="auto"
									pointer-events="auto"
								>
									tel.:{" "}
								</Strong>
								+48 664 729 101
							</Link>
							<Link
								text-decoration-line="initial"
								color="--fonText"
								display="flex"
								align-items="center"
								href="mailto:biuro@ventatrans.pl"
								font="--base"
							>
								<Strong
									hyphens="manual"
									font="--lead"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
								>
									e-mail:{" "}
								</Strong>
								biuro@ventatrans.pl
							</Link>
						</Box>
					</Box>
					<Box
						max-width="none"
						position="relative"
						color="--text2"
						width="90%"
						sm-width="80%"
						padding="0 0 0 40px"
						margin="32px 0 0 0"
					>
						<Icon
							position="absolute"
							size="32px"
							width="32px"
							height="32px"
							color="--green"
							top="0"
							left="0"
							category="fa"
							icon={FaMapPin}
						/>
						<Text
							font="--lead"
							sm-font="--leadMob"
							width="100%"
							color="--fonText"
							as="p"
							margin="6px 0"
						>
							Prague, Czech Republic
						</Text>
						<Box display="flex" justify-content="left" lg-flex-direction="column">
							<Link
								margin="0px 10px 0px 0px"
								href="tel:+420605918159"
								font="--base"
								text-decoration-line="initial"
								color="--fonText"
								display="flex"
								align-items="center"
							>
								<Strong
									text-overflow="clip"
									hyphens="manual"
									font="--lead"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
								>
									tel.:{" "}
								</Strong>
								+420 605 918 159{" "}
							</Link>
							<Link
								font="--base"
								text-decoration-line="initial"
								color="--fonText"
								display="flex"
								align-items="center"
								href="mailto:info@ventatrans.cz"
							>
								<Strong
									font="--lead"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
								>
									e-mail:{" "}
								</Strong>
								info@ventatrans.cz
							</Link>
						</Box>
					</Box>
					<Box
						position="relative"
						color="--text2"
						width="90%"
						sm-width="80%"
						padding="0 0 0 40px"
						margin="32px 0 0 0"
						max-width="none"
					>
						<Icon
							position="absolute"
							left="0"
							width="32px"
							height="32px"
							color="--green"
							size="32px"
							top="0"
							category="fa"
							icon={FaMapPin}
						/>
						<Text
							sm-font="--leadMob"
							width="100%"
							color="--fonText"
							as="p"
							margin="6px 0"
							font="--lead"
						>
							Koper, Slovenia
						</Text>
						<Box display="flex" justify-content="left" lg-flex-direction="column">
							<Link
								href="tel:+38664264596"
								font="--base"
								text-decoration-line="initial"
								color="--fonText"
								display="flex"
								align-items="center"
								margin="0px 10px 0px 0px"
							>
								<Strong
									text-overflow="clip"
									hyphens="manual"
									font="--lead"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
								>
									tel.:{" "}
								</Strong>
								+386 064 264 596{" "}
							</Link>
							<Link
								color="--fonText"
								display="flex"
								align-items="center"
								href="mailto:info@ventatrans.si"
								font="--base"
								text-decoration-line="initial"
							>
								<Strong
									font="--lead"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
								>
									e-mail:{" "}
								</Strong>
								info@ventatrans.si
							</Link>
						</Box>
					</Box>
					<Box
						color="--text2"
						width="90%"
						sm-width="80%"
						padding="0 0 0 40px"
						margin="32px 0 0 0"
						max-width="none"
						position="relative"
						display="none"
					>
						<Icon
							width="32px"
							category="fa"
							height="32px"
							top="0"
							left="0"
							icon={FaMapPin}
							color="--green"
							position="absolute"
							size="32px"
						/>
						<Text
							width="100%"
							color="--fonText"
							as="p"
							margin="6px 0"
							font="--lead"
						>
							St.Petersburg, Russia
						</Text>
						<Box display="flex" justify-content="left" lg-flex-direction="column">
							<Link
								color="--fonText"
								display="flex"
								align-items="center"
								margin="0px 10px 0px 0px"
								href="tel:+79219807180"
								font="--base"
								text-decoration-line="initial"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									font="--lead"
								>
									tel.:{" "}
								</Strong>
								+7 921 980 71 80{" "}
							</Link>
							<Link
								href="mailto:info@ventatrans.cz"
								font="--base"
								text-decoration-line="initial"
								color="--fonText"
								display="flex"
								align-items="center"
							>
								<Strong
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									font="--lead"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
								>
									e-mail:{" "}
								</Strong>
								info@ventatrans.cz
							</Link>
						</Box>
					</Box>
					<Box
						display="none"
						padding="0 0 0 40px"
						position="relative"
						sm-width="80%"
						width="90%"
						flex-direction="column"
						margin="32px 0 0 0"
						max-width="none"
						color="--text2"
					>
						<Icon
							size="32px"
							left="0"
							category="fa"
							width="32px"
							position="absolute"
							top="0"
							icon={FaMapPin}
							height="32px"
							color="--green"
						/>
						<Text
							as="p"
							margin="6px 0"
							font="--lead"
							width="100%"
							color="--fonText"
						>
							Samara, Russia
						</Text>
						<Box display="flex" justify-content="left" lg-flex-direction="column">
							<Link
								href="tel:+79277194094"
								font="--base"
								text-decoration-line="initial"
								color="--fonText"
								display="flex"
								align-items="center"
								margin="0px 10px 0px 0px"
							>
								<Strong
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									font="--lead"
									overflow-wrap="normal"
								>
									tel.:{" "}
								</Strong>
								+7 927 719 40 94{" "}
							</Link>
							<Link
								align-items="center"
								href="mailto:info@ventatrans.cz"
								font="--base"
								text-decoration-line="initial"
								color="--fonText"
								display="flex"
							>
								<Strong
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									font="--lead"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
								>
									e-mail:{" "}
								</Strong>
								info@ventatrans.cz
							</Link>
						</Box>
					</Box>
				</StackItem>
				<StackItem width="40%" md-width="100%" margin="30px 0px 0px 0px" padding="16px 16px 16px 16px">
					<Formspree endpoint="xpzkaljw" padding="0px 20px 0px 20px" sm-padding="0px 20px 0px 30px" sm-width="100%">
						<Stack gap="16px" padding="15px 0px 0px 0px" margin="0 -8px -8px -8px" width="100%">
							<Text
								md-font="--headline3"
								sm-width="100%"
								as="h3"
								font="--headline3"
								margin="0 0 20px 0"
								color="--fonText"
								padding="0px 0px 0px 8px"
								text-transform="uppercase"
							>
								LEAVE US A MESSAGE
							</Text>
							<StackItem width="100%">
								<Override slot="StackItemContent" flex-direction="column" />
								<Text font="--base" margin="0 0 4px 0" color="--fonText">
									Name
								</Text>
								<Input placeholder="Enter your name" max-width="400px" width="100%" name="name" />
							</StackItem>
							<StackItem width="100%">
								<Override slot="StackItemContent" flex-direction="column" />
								<Text font="--base" margin="0 0 4px 0" color="--fonText">
									Email
								</Text>
								<Input
									name="email"
									placeholder="Enter your e-mail"
									max-width="400px"
									width="100%"
									type="email"
								/>
							</StackItem>
							<StackItem width="100%">
								<Override slot="StackItemContent" flex-direction="column" />
								<Text font="--base" margin="0 0 4px 0" color="--fonText">
									Message
								</Text>
								<Input
									name="message"
									max-width="400px"
									placeholder="Please describe your question"
									as="textarea"
									rows="4"
									width="100%"
								/>
							</StackItem>
							<StackItem width="100%">
								<Button background="--color-logo">
									Send
								</Button>
							</StackItem>
						</Stack>
					</Formspree>
				</StackItem>
			</Stack>
		</Section>
		{"    "}
		<Section background="--color-logo" quarkly-title="Footer">
			<Override slot="SectionContent" sm-margin="0px auto 0px auto" />
			<Box display="flex" sm-font="--base" justify-content="center">
				<Text
					margin="16px 0px 16px 0"
					color="--fonText"
					sm-font="--text11Mob"
					sm-padding="0px 5px 0px 0px"
					padding="0px 10px 0px 0px"
					md-font="--baseMob"
					font="--lead"
					display="block"
				>
					© 2021 Venta-Trans Europe.
				</Text>
				<Text
					sm-font="--text11Mob"
					md-font="--baseMob"
					padding="0px 10px 0px 0px"
					sm-padding="0px 5px 0px 0px"
					font="--lead"
					display="block"
					margin="16px 0px 16px 0"
					color="--fonText"
				>
					All Rights Reserved.
				</Text>
				<Link
					word-break="normal"
					text-indent="0"
					color="--fonText"
					md-font="--baseMob"
					target="_self"
					text-overflow="clip"
					font="--lead"
					position="static"
					sm-font="--text11Mob"
					margin="16px 0px 16px 0px"
					href="/privacypolicy"
					overflow-wrap="normal"
					white-space="normal"
					hyphens="manual"
					sm-padding="0px 0px 0px 0"
				>
					Privacy Policy
				</Link>
			</Box>
		</Section>
		<Components.Cookies>
			<Override slot="Button" font="--baseMob" />
			<Override slot="Text" font="--baseMob" margin="16px 80px 16px 80px">
				This website uses cookies to provide you with a better online
experience. If you select "Accept" or access any content of our
website without customising your choices, you agree to the use of cookies.{" "}
				<Link
					overflow-wrap="normal"
					white-space="normal"
					text-indent="0"
					hyphens="manual"
					href="/privacypolicy"
					target="_self"
					word-break="normal"
					text-overflow="clip"
					color="--fon2"
				>
					Find
out more about our Privacy & Cookie Policy
				</Link>
				.
			</Override>
		</Components.Cookies>
		<RawHtml>
			<style place={"endOfBody"} rawKey={"608142d6ab4dd832c0755f1a"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script src={"https://www.googletagmanager.com/gtag/js?id=G-118BTS02HM"} async={true} place={"endOfHead"} rawKey={"6082f9b76cd311d618e2da67"} />
			<script place={"endOfHead"} rawKey={"6082f9d0a29b8aa4421cb038"}>
				{"window.dataLayer = window.dataLayer || [];\nfunction gtag(){dataLayer.push(arguments);}\ngtag('js', new Date());\ngtag('config', 'G-118BTS02HM');"}
			</script>
			<script src={"https://fast.wistia.com/embed/medias/ivn4prep36.jsonp"} async={true} place={"endOfHead"} rawKey={"6083012406cc56de18db89d6"} />
			<script async={true} src={"https://fast.wistia.com/assets/external/E-v1.js"} place={"endOfHead"} rawKey={"608301398ecb4f30a2c73278"} />
		</RawHtml>
	</Theme>;
});
